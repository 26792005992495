import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import creditAction from '../../../redux/actions/creditAction'
import axiosInstance from '../../../services/axios/axios'
import ErrorComponent from '../../components/error/ErrorComponent'
import RippleLoader from '../../components/loader/RippleLoader'
import AdvanceUserForm from '../../components/search-user/advanced/AdvanceUserForm'
import AdvanceUserTable from '../../components/search-user/advanced/AdvanceUserTable'
import AdvanceUserFormV2 from '../../components/search-user/advanced/AdvancedUserFormV2'

const getSearchedUserFromLocal = () => {
  const data = sessionStorage.getItem('ADVANCED_USER')
  if (data) return JSON.parse(data)

  return []
}

const AdvancedSearch = () => {
  const [loading, setLoading] = useState(false)
  const [searchedUser, setSearchedUser] = useState(getSearchedUserFromLocal())
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const refetchUserData = async () => {
    const query = JSON.parse(sessionStorage.getItem('ADVANCED_USER_QUERY'))

    if (query === null || query === '') {
      return
    }
    try {
      const { data } = await axiosInstance.get(query)
      setSearchedUser(JSON.parse(data.body).message)
    } catch (err) {
      console.log(err.message)
    }
  }

  return (
    <div className="relative flex w-full min-h-[80vh]">
      <div className="min-w-[20rem]">
        <div className="sticky top-0 p-6 mt-2 bg-white rounded-r-lg shadow-lg h-fit">
          <h2 className="mb-4 text-2xl font-bold text-indigo-600">
            Advanced Search
          </h2>
          {/* <AdvanceUserForm
            setSearchedUser={setSearchedUser}
            setLoading={setLoading}
          /> */}
          <AdvanceUserFormV2
            setSearchedUser={setSearchedUser}
            setLoading={setLoading}
          />
        </div>
      </div>

      {loading ? (
        <RippleLoader />
      ) : (
        <div className="flex-1 p-4 overflow-auto sm:p-6">
          <div>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <AdvanceUserTable
                fetchCreditData={() =>
                  dispatch(creditAction.fetchUserCredits(user.user_id))
                }
                data={searchedUser}
                refetchUserData={refetchUserData}
              />
            </ErrorBoundary>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdvancedSearch
