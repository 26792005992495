export const jobCategoryData = [
  'Application designer',
  'Application developer',
  'Application engineer',
  'Application support analyst',
  'Artificial intelligence engineer',
  'Business systems analyst',
  'Chief information officer (CIO)',
  'Chief technology officer (CTO)',
  'Cloud architect',
  'Cloud computing engineer',
  'Cloud consultant',
  'Cloud product manager',
  'Cloud services developer',
  'Cloud services provider',
  'Cloud system administrator',
  'Cloud systems engineer',
  'Computer and information research manager',
  'Computer and information research scientist',
  'Computer data scientist',
  'Computer forensic investigator',
  'Computer network specialist',
  'Computer operator',
  'Computer programmer',
  'Computer support technician',
  'Computer systems analyst',
  'Computer technician',
  'Cyber security manager',
  'Cyber security specialist',
  'Data architect',
  'Data modeler',
  'Data quality manager',
  'Data scientist',
  'Database analyst',
  'Database coordinator',
  'Database developer',
  'Database engineer',
  'Database manager',
  'Database specialist',
  'Desktop support specialist',
  'DevOps engineer',
  'Digital Marketing',
  'Digital Marketing Executive',
  'Digital Marketing Manager',
  'Director of technology',
  'Event Coordinator',
  'Event Management',
  'Event Manager',
  'Frameworks specialist',
  'Front-end designer',
  'Front-end developer',
  'Full-stack developer',
  'Game developer',
  'Graphic Design',
  'Graphic Designer',
  'Help desk analyst',
  'Help desk support',
  'Help desk technician',
  'Help desk worker',
  'IT coordinator',
  'IT director',
  'IT manager',
  'IT project manager',
  'IT sales director',
  'IT sales executive',
  'IT security analyst',
  'IT support specialist',
  'IT technician',
  'Information architect',
  'Information management systems director',
  'Information security analyst',
  'Information security consultant',
  'Information security engineer',
  'Information security manager',
  'Information security program manager',
  'Information security project manager',
  'Interaction designer',
  'Internet engineer',
  'Iteration manager',
  'Lead programmer',
  'Management information director',
  'Marketing',
  'Marketing Executive',
  'Marketing Manager',
  'Mobile developer',
  'Multimedia architect',
  'Network administrator',
  'Network analyst',
  'Network architect',
  'Network infrastructure specialist',
  'Network operations engineer',
  'Network reliability engineer',
  'Network security engineer',
  'Network technician',
  'Operations analyst',
  'Problem manager',
  'Product Development',
  'Product Manager',
  'Sales',
  'Sales Executive',
  'Sales Manager',
  'Search engine optimization (SEO) consultant',
  'Security specialist',
  'Senior Graphic Designer',
  'Senior IT consultant',
  'Senior Product Manager',
  'Senior systems analyst',
  'SEO manager',
  'Software architect',
  'Software development engineer',
  'Software development manager',
  'Software engineer',
  'Software test engineer',
  'Solutions architect',
  'Support specialist',
  'Systems analyst',
  'Systems designer',
  'Technical account manager',
  'Technical assistance specialist',
  'Technical lead',
  'Technical operations officer',
  'Technical specialist',
  'Technical support',
  'Technology assistant',
  'Technology manager',
  'Technology specialist',
  'UX/UI researcher',
  'UX/UI specialist',
  'User experience (UX) designer',
  'User interface (UI) designer',
  'Web analytics developer',
  'Web content manager',
  'Web designer',
  'Web development manager',
  'Web development project manager',
  'Web engineer',
  'Web producer',
  'Web producers',
  'Web project manager',
  'Webmaster'
]
