import React from 'react'

const RecruiterHeader = ({
  data = {
    labels: ['New Applicants', 'In Progress', 'Selected', 'Rejected'],
    series: [600, 300, 200, 100]
  },
  label = 'jobs'
}) => {
  const { applicants, connections } = data

  const { JOINED } = applicants

  const total = Object.values(applicants).reduce((a, b) => a + b, 0)

  const selected = JOINED + applicants['AI SELECTED']

  return (
    <div className="grid grid-cols-4 gap-10 lg:flex-1">
      <div className="flex flex-col items-center min-h-[150px] justify-center gap-3 p-4 font-bold text-white transition-all duration-300 transform rounded-lg shadow-custom bg-gradient-to-r from-purple-500 to-purple-700 hover:scale-105 hover:shadow-lg">
        <h2 className="text-base font-semibold">Total Applications</h2>
        <p className="text-2xl md:text-4xl">{total}</p>
      </div>
      <div className="flex flex-col items-center min-h-[150px] justify-center gap-3 p-4 font-bold text-white transition-all duration-300 transform rounded-lg shadow-custom bg-gradient-to-r from-pink-500 to-pink-700 hover:scale-105 hover:shadow-lg">
        <h2 className="text-base font-semibold">Total Selections</h2>
        <p className="text-2xl md:text-4xl">{selected}</p>
      </div>
      <div className="flex flex-col items-center min-h-[150px] justify-center gap-3 p-4 font-bold text-white transition-all duration-300 transform rounded-lg shadow-custom bg-gradient-to-r from-blue-500 to-blue-700 hover:scale-105 hover:shadow-lg">
        <h2 className="text-base font-semibold">
          {label === 'Projects'
            ? 'Total Projects Created'
            : 'Total Jobs Created'}
        </h2>
        <p className="text-2xl md:text-4xl">{data[label]?.length ?? 0}</p>
      </div>
      <div className="flex flex-col items-center min-h-[150px] justify-center gap-3 p-4 font-bold text-white transition-all duration-300 transform rounded-lg shadow-custom bg-gradient-to-r from-green-500 to-green-700 hover:scale-105 hover:shadow-lg">
        <h2 className="text-base font-semibold">Total Connections</h2>
        <p className="text-2xl md:text-4xl">{connections}</p>
      </div>
    </div>
  )
}

export default RecruiterHeader
