import React from 'react'
import ReactApexChart from 'react-apexcharts'

const RecruiterChart = ({
  chartData = {
    data: [],
    categories: []
  },
  heading
}) => {
  const { data, categories } = chartData
  const series = [
    {
      name: 'students',
      data
    }
  ]

  const options = {
    chart: {
      type: 'bar',
      foreColor: '#000000',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top'
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.5,
        type: 'vertical',
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.5,
        stops: [0, 100, 100, 100]
      }
    },
    grid: {
      show: false
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#333']
      }
    },
    tooltip: {
      theme: 'dark'
    },

    xaxis: {
      categories,
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5
          }
        }
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true
      }
    }
  }

  return (
    <div className="p-3 bg-white sm:p-6 ">
      <div className="flex items-center justify-between mb-6">
        <h3 className="mr-2 font-semibold truncate ">{heading}</h3>
      </div>

      <div className="text-gray-600 " id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={300}
        />
      </div>
    </div>
  )
}

export default RecruiterChart
