import React, { Fragment, useEffect, useState } from 'react'
import uuid from 'react-uuid'
import TableSearch from '../../../ui-component/inputs/TableSearch'
import { advancedTableHeader } from '../TableData'
import SingleAdvanceUser from './SingleAdvanceUser'

const AdvanceUserTable = ({ data, fetchCreditData, refetchUserData }) => {
  const [filterApplicantData, setfilterApplicantData] = useState(data)
  const [applicantText, setApplicantText] = useState('')

  const filterChange = (text) => {
    const filteredData = data.filter(
      (user) =>
        user.language.toUpperCase().includes(text.toUpperCase()) ||
        user.github_id.toUpperCase().includes(text.toUpperCase())
    )

    setfilterApplicantData(filteredData)
  }

  const onTextChange = (e) => {
    setApplicantText(e.target.value)
    filterChange(e.target.value)
  }

  useEffect(() => {
    setfilterApplicantData(data)
  }, [data])
  return (
    <div className="flex flex-col gap-4">
      <TableSearch
        value={applicantText}
        onChange={onTextChange}
        placeholder="Search Searched Users"
      />
      <div className="w-full max-h-screen overflow-auto">
        <table
          className={`p-4 w-full h-full rounded-lg rounded-b-none shadow-custom bg-white`}
        >
          <tbody className="text-xs xl:text-sm ">
            <tr className=" bg-indigo-50">
              {advancedTableHeader.map((head) => (
                <th key={uuid()} className="p-4 py-2 text-left text-indigo-600">
                  {head.title}
                </th>
              ))}
            </tr>

            {filterApplicantData.length !== 0 &&
              filterApplicantData.map((applicantData, idx) => (
                <SingleAdvanceUser
                  key={uuid()}
                  refetchUserData={refetchUserData}
                  fetchCreditData={fetchCreditData}
                  data={applicantData}
                  idx={idx}
                  lastIdx={idx >= filterApplicantData.length - 4}
                  searchedUsers={data}
                />
              ))}
          </tbody>
        </table>
        {filterApplicantData.length === 0 && (
          <Fragment>
            <p className="w-full py-4 my-4 text-center text-gray-500 font-600">
              No Applicants Found
            </p>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default AdvanceUserTable
