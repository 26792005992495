import React, { useState } from 'react'
import { IoAddCircle } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import IconButton from '../../ui-component/buttons/IconButton'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import CreateJobModal from '../create-job/CreateJobModal'
import JobListingWrapper from './JobListingWrapper'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'

const JobListing = ({ fetchAllJobs, allJobData }) => {
  const [showCreateJobModal, setShowCreateJobModal] = useState(false)
  const { state } = useLocation()

  const [tab, setTab] = useState(state?.tabIndex ? state?.tabIndex : 1)
  const { user } = useSelector((state) => state.auth)

  const tabs = [
    {
      name: 'Open Jobs',
      component: (
        <JobListingWrapper
          allJobData={allJobData.filter((job) => job.status === 'OPEN')}
          fetchAllJobs={fetchAllJobs}
        />
      )
    },
    {
      name: 'Closed Jobs',
      component: (
        <JobListingWrapper
          allJobData={allJobData.filter((job) => job.status === 'CLOSE')}
          fetchAllJobs={fetchAllJobs}
        />
      )
    },
    {
      name: 'Processing Jobs',
      component: (
        <JobListingWrapper
          allJobData={allJobData.filter((job) => job.status === 'PENDING')}
          fetchAllJobs={fetchAllJobs}
        />
      )
    },
    {
      name: 'Pending Jobs',
      component: (
        <JobListingWrapper
          allJobData={allJobData.filter(
            (job) => job.status === 'OPEN_UNPUBLISHED'
          )}
          fetchAllJobs={fetchAllJobs}
        />
      ),
      hidden: user.type !== 'AGENCY'
    }
  ]

  return (
    <TabGroup>
      <div className="flex flex-col-reverse items-end justify-between gap-3 m-auto mb-6 max-w-7xl sm:flex-row sm:items-center md:mb-10 ">
        <TabList className="flex gap-4 p-2 bg-gray-100 rounded-lg max-w-max">
          {tabs
            .filter((tab) => !tab.hidden)
            .map(({ name }) => (
              <Tab
                key={name}
                className="rounded-lg py-1 data-[selected]:shadow px-3 text-sm/6 font-semibold text-gray-500 data-[selected]:text-black focus:outline-none data-[selected]:bg-white data-[selected]:data-[hover]:bg-gray-50 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                {name}
              </Tab>
            ))}
        </TabList>

        <IconButton
          toolTipTitle={'Create Job'}
          onClick={() => setShowCreateJobModal(true)}
          text={'Create Job'}
          icon={<IoAddCircle />}
          type="primary"
        />
      </div>
      <TabPanels className="mt-3">
        {tabs
          .filter((tab) => !tab.hidden)
          .map(({ name, component }) => (
            <TabPanel key={name} className="p-3 rounded-xl bg-white/5">
              {component}
            </TabPanel>
          ))}
      </TabPanels>

      {showCreateJobModal && (
        <div className="fixed top-0 left-0 right-0 z-10 grid w-full h-screen text-left bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm place-items-center">
          <div className="z-50 w-full max-w-4xl p-4 m-auto">
            <ClickWrapper func={() => {}}>
              <CreateJobModal
                refetchData={fetchAllJobs}
                close={() => setShowCreateJobModal(false)}
              />
            </ClickWrapper>
          </div>
        </div>
      )}
    </TabGroup>
  )
}

export default JobListing
