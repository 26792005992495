import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from '../error/ErrorComponent'
import RecruiterChart from './charts/RecruiterCharts'
import RecruiterHeader from './header/RecruiterHeader'

import RecruiterApplicantChart from './charts/ApplicationCharts'
import RecruiterJobsCardList from './dashboard-table/RecruiterJobsCardList'

const JobDashboard = ({ recruiterData }) => {
  return (
    <div className="flex flex-col gap-10">
      <ErrorBoundary FallbackComponent={ErrorComponent}>
        <RecruiterHeader data={recruiterData} />
      </ErrorBoundary>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        <ErrorBoundary FallbackComponent={ErrorComponent}>
          <RecruiterApplicantChart data={recruiterData} />
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorComponent}>
          <div className="rounded-xl shadow-custom">
            <RecruiterChart
              heading={'Jobs Created'}
              chartData={{
                data: [
                  recruiterData.jobs.filter((job) => job.status === 'OPEN')
                    .length,
                  recruiterData.jobs.length -
                    recruiterData.jobs.filter((job) => job.status === 'OPEN')
                      .length
                ],
                categories: ['Open Jobs', 'Closed Jobs']
              }}
            />
          </div>
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorComponent}>
          <div className="rounded-xl shadow-custom">
            <RecruiterChart
              heading={'Total Applications and Selections'}
              chartData={{
                data: [
                  Object.values(recruiterData.applicants).reduce(
                    (a, b) => a + b,
                    0
                  ),
                  recruiterData.applicants.JOINED +
                    recruiterData.applicants['AI SELECTED']
                ],
                categories: ['Total Applications', 'Total Selections']
              }}
            />
          </div>
        </ErrorBoundary>
      </div>
      <div className="rounded-xl shadow-custom">
        <ErrorBoundary FallbackComponent={ErrorComponent}>
          <RecruiterChart
            heading={'Applicants'}
            chartData={{
              data: Object.values(recruiterData.applicants),
              categories: Object.keys(recruiterData.applicants)
            }}
          />
        </ErrorBoundary>
      </div>
      <ErrorBoundary FallbackComponent={ErrorComponent}>
        <RecruiterJobsCardList jobData={recruiterData?.jobs ?? []} />
      </ErrorBoundary>
    </div>
  )
}

export default JobDashboard
