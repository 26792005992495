import React from 'react'
import AsyncSelect from 'react-select/async'

export const MultiAsyncSelect = ({
  promiseOptions,
  onChange,
  values,
  isMulti = false
}) => {
  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={promiseOptions}
      onChange={onChange}
      values={values}
      isMulti={isMulti}
      className="text-sm border-gray-200"
    />
  )
}
