import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'

const SingleRecruitersJobCard = ({ data, idx }) => {
  const {
    company_logo,
    create_date,
    description,
    job_id,
    job_name,
    key_skills,
    total_applicant
  } = data

  const navigate = useNavigate()

  return (
    <div
      onClick={() => navigate(`/job-listing/${job_id}`)}
      className="flex flex-col p-6 min-h-[250px] bg-white rounded-xl shadow-lg hover:shadow-2xl hover:scale-[1.05] transition-all duration-300 ease-in-out border border-gray-200 cursor-pointer"
    >
      {/* Job Header */}
      <div className="flex items-center gap-4 mb-4">
        {company_logo && company_logo !== '' && (
          <img
            className="object-cover rounded-lg w-14 h-14"
            src={company_logo}
            alt={job_name}
          />
        )}
        <div>
          <h2 className="text-xl font-semibold text-gray-900">{job_name}</h2>
          <p className="text-sm text-gray-500">
            {create_date.split(' ')[0].split('-').reverse().join('/')}
          </p>
        </div>
      </div>

      {/* Job Description */}
      <div className="mb-4">
        <p className="font-semibold text-gray-700">Description:</p>
        <p className="text-sm text-gray-600 line-clamp-2">
          {ReactHtmlParser(description)}
        </p>
      </div>

      {/* Skills */}
      {key_skills?.length > 0 && (
        <div className="mb-4">
          <p className="font-semibold text-gray-700">Skills:</p>
          <div className="flex flex-wrap gap-2">
            {key_skills &&
              key_skills.split(',').map((skill) => (
                <span
                  key={uuid()}
                  className="px-3 py-1 text-xs font-medium text-gray-700 bg-gray-200 rounded-full shadow"
                >
                  {skill}
                </span>
              ))}
          </div>
        </div>
      )}

      {/* Footer */}
      <div className="flex items-center justify-between mt-auto">
        <div className="flex items-center gap-2">
          <p className="text-sm font-semibold text-gray-600">
            {total_applicant} Applicants
          </p>
        </div>
        <p className="text-xs text-gray-500">Job ID: {job_id}</p>
      </div>
    </div>
  )
}

export default SingleRecruitersJobCard
