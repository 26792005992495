import React from 'react'
import ReactApexChart from 'react-apexcharts'

const RecruiterApplicantChart = ({
  data = {
    labels: ['New Applicants', 'In Progress', 'Selected', 'Rejected'],
    series: [600, 300, 200, 100]
  },
  label = 'Applications'
}) => {
  const { applicants } = data

  const { NEW, JOINED, REJECT } = applicants

  const total = Object.values(applicants).reduce((a, b) => a + b, 0)

  const selected = JOINED + applicants['AI SELECTED']

  const rejected = REJECT + applicants['REJECT-PD']

  const inProgress = total - (selected + rejected)

  const pieSeries = [NEW, selected, inProgress, rejected]

  const options = {
    chart: {
      type: 'donut'
    },
    stroke: {
      width: 0
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360
      }
    },
    labels: ['New Applications', 'Selected', 'In Progress', 'Rejected'],
    dataLabels: {
      enabled: true,
      textAnchor: 'middle'
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'diagonal2',
        shadeIntensity: 0.5,
        gradientToColors: ['#FF3F2E', '#059669', '#2563EB', '#F59E0B'], // Gradient end colors
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    colors: ['#FF7E79', '#34D399', '#60A5FA', '#FBBF24'], // Start colors for gradient
    legend: {
      formatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex]
      },
      position: 'bottom'
    },
    title: {
      text: label,
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 600
      }
    }
  }
  return (
    <div className="flex flex-col w-full gap-10 lg:flex-row">
      <div
        id="chart"
        className="grid py-10 text-xs text-center text-white place-items-center lg:flex-1 shadow-custom rounded-xl"
      >
        <ReactApexChart
          options={options}
          series={pieSeries}
          type="pie"
          height={400}
          className="grid w-full h-full place-items-center"
        />
      </div>
    </div>
  )
}

export default RecruiterApplicantChart
