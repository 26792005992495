import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ERROR_PAGE } from '../../../navigation/routes'
import axiosInstance from '../../../services/axios/axios'
import GithubUsersTable from '../../components/admin/GithubUsersTable'
import RippleLoader from '../../components/loader/RippleLoader'
import ProjectTable from '../../components/recruiter-dashboard/dashboard-table/ProjectCardList'
import RecruiterJobsCardList from '../../components/recruiter-dashboard/dashboard-table/RecruiterJobsCardList'
import ApplicantAdditionTable from '../../components/self-jobs/ApplicantAdditionTable'
import SuperAdminUserTable from '../../components/super-admin/SuperAdminUserTable'

const AdminActivity = () => {
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)
  const [activityData, setActivityData] = useState({})
  const [loading, setLoading] = useState(true)

  const tabs = [
    {
      name: 'Jobs',
      component: <RecruiterJobsCardList jobData={activityData?.jobs ?? []} />
    },
    {
      name: 'Projects',
      component: <ProjectTable projectData={activityData?.projects ?? []} />
    },
    {
      name: 'Users',
      component: <SuperAdminUserTable data={activityData?.users ?? []} />
    },
    {
      name: 'Applicants',
      component: (
        <ApplicantAdditionTable
          applicantsData={activityData?.applicant ?? []}
          isAdmin={true}
        />
      )
    },
    {
      name: 'Github Users',
      component: (
        <GithubUsersTable githubUsers={activityData?.github_users ?? []} />
      )
    }
  ]

  const fetchAdminActivity = async () => {
    try {
      const { data } = await axiosInstance(
        `https://to5ycnuhg9.execute-api.ap-south-1.amazonaws.com/Dev/adminGetRecentData?n=7`
      )
      console.log(data)
      if (data.message) {
        setActivityData(data.message)
      }
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (user.type !== 'SUPER_ADMIN') {
      navigate(ERROR_PAGE)
      return
    }
    fetchAdminActivity()
  }, [])

  return (
    <div className="min-h-screen p-6 md:p-10">
      {loading ? (
        <RippleLoader />
      ) : (
        <TabGroup>
          <TabList className="flex gap-4 p-2 bg-gray-100 rounded-lg max-w-max">
            {tabs
              .filter((tab) => !tab.hidden)
              .map(({ name }) => (
                <Tab
                  key={name}
                  className="rounded-lg py-1 data-[selected]:shadow px-3 text-sm/6 font-semibold text-gray-500 data-[selected]:text-black focus:outline-none data-[selected]:bg-white data-[selected]:data-[hover]:bg-gray-50 data-[focus]:outline-1 data-[focus]:outline-white"
                >
                  {name}
                </Tab>
              ))}
          </TabList>

          <TabPanels className="mt-3">
            {tabs
              .filter((tab) => !tab.hidden)
              .map(({ name, component }) => (
                <TabPanel key={name} className="p-3 rounded-xl bg-white/5">
                  {component}
                </TabPanel>
              ))}
          </TabPanels>
        </TabGroup>
      )}
    </div>
  )
}

export default AdminActivity
