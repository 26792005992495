import React, { Fragment, useEffect, useState } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { RiMessage3Line } from 'react-icons/ri'
import { TiCancel } from 'react-icons/ti'
import uuid from 'react-uuid'
import IconButton from '../../ui-component/buttons/IconButton'
import TableSearch from '../../ui-component/inputs/TableSearch'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import SendMailModal from '../job-listing/SendMailModal'
import SingleShortlistUser from './SingleShortlistUser'
import { ShortlistTableHeader } from './TableData'

const ShortlistUserTable = ({
  data,
  fetchCreditData,
  credits,
  refetchData
}) => {
  const [filterApplicantData, setfilterApplicantData] = useState(data)
  const [applicantText, setApplicantText] = useState('')
  const [sendMultipleMessage, setSendMultipleMessage] = useState(false)
  const [showMultipleMessageModal, setShowMultipleMessageModal] =
    useState(false)
  const [usersToMessage, setUsersToMessage] = useState([])
  const filterChange = (text) => {
    const filteredData = data.filter(
      (user) =>
        user.tag.toUpperCase().includes(text.toUpperCase()) ||
        user.github_id.toUpperCase().includes(text.toUpperCase())
    )

    setfilterApplicantData(filteredData)
  }

  const onTextChange = (e) => {
    setApplicantText(e.target.value)
    filterChange(e.target.value)
  }

  useEffect(() => {
    setfilterApplicantData(data)
  }, [data])

  useEffect(() => {
    console.log('users to message', usersToMessage)
  }, [usersToMessage])

  return (
    <div className="relative flex flex-col gap-4 mt-10">
      {showMultipleMessageModal && (
        <div className="fixed top-0 left-0 right-0 z-10 grid w-full h-screen text-left bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm place-items-center">
          <div className="z-50 w-full max-w-2xl p-6 m-auto">
            <ClickWrapper func={() => setShowMultipleMessageModal(false)}>
              <SendMailModal
                refetchData={() => {
                  fetchCreditData()
                  refetchData()
                }}
                recieverDetails={usersToMessage}
                close={() => setShowMultipleMessageModal(false)}
                multiple
              />
            </ClickWrapper>
          </div>
        </div>
      )}
      <div className="flex flex-col-reverse items-end justify-between gap-4 mb-6 sm:flex-row sm:items-center md:mb-10">
        <TableSearch
          value={applicantText}
          onChange={onTextChange}
          placeholder="Search Shortlisted Users"
        />
        <div className="flex items-center gap-3 text-xs">
          {sendMultipleMessage ? (
            <IconButton
              toolTipTitle={`Cancel`}
              text={'Cancel Multiple'}
              icon={<TiCancel />}
              onClick={() => {
                setSendMultipleMessage(false)
                setUsersToMessage([])
              }}
              type="primary"
            />
          ) : (
            <IconButton
              toolTipTitle={`Send mail to multiple applicants`}
              text={'Send Multiple'}
              icon={<RiMessage3Line />}
              onClick={() => setSendMultipleMessage(true)}
              type="primary"
            />
          )}

          {usersToMessage.length > 1 && (
            <IconButton
              toolTipTitle={`Compose Email`}
              text={'Compose Mail'}
              icon={<AiOutlineMail />}
              onClick={() => setShowMultipleMessageModal(true)}
              type="primary"
            />
          )}
        </div>
      </div>

      <div className="w-full max-h-screen overflow-auto">
        <table className={`p-4 w-full rounded-lg rounded-b-none shadow-custom`}>
          <tbody className="text-xs rounded-lg xl:text-sm ">
            <tr className=" bg-indigo-50">
              {ShortlistTableHeader.map((head) => (
                <th key={uuid()} className="p-4 py-2 text-left text-indigo-600">
                  {head.title}{' '}
                </th>
              ))}
            </tr>

            {filterApplicantData.length !== 0 &&
              filterApplicantData.map((applicantData, idx) => (
                <SingleShortlistUser
                  key={uuid()}
                  fetchCreditData={fetchCreditData}
                  data={applicantData}
                  idx={idx}
                  lastIdx={idx >= filterApplicantData.length - 4}
                  searchedUsers={data}
                  refetchData={refetchData}
                  sendMultipleMessage={sendMultipleMessage}
                  usersToMessage={usersToMessage}
                  credits={credits}
                  setUsersToMessage={setUsersToMessage}
                />
              ))}
          </tbody>
        </table>
        {filterApplicantData.length === 0 && (
          <Fragment>
            <p className="w-full py-4 my-4 text-center text-gray-500 font-600">
              No Applicants Found
            </p>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default ShortlistUserTable
