import React, { Fragment } from 'react'
import uuid from 'react-uuid'
import SingleRecruitersJobCard from './SingleRecruitersJobCard'
import { Link } from 'react-router-dom'

const RecruiterJobsCardList = ({ jobData = [] }) => {
  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-6">
        <h3 className="mr-2 font-semibold truncate">All Jobs</h3>
      </div>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {jobData.length !== 0 &&
          jobData
            .slice(0, 9)
            .map((singleJob, idx) => (
              <SingleRecruitersJobCard
                key={uuid()}
                data={singleJob}
                idx={idx}
              />
            ))}
      </div>

      {jobData.length > 9 && (
        <div className="flex justify-center mt-4">
          <Link to="/job-listing" className="text-blue-500">
            View All Jobs
          </Link>
        </div>
      )}

      {jobData.length === 0 && (
        <Fragment>
          <p className="w-full py-4 my-4 text-center text-gray-500 font-600">
            No Jobs Found
          </p>
        </Fragment>
      )}
    </div>
  )
}

export default RecruiterJobsCardList
