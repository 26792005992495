import { Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import HtmlParser from 'react-html-parser'
import { FiMessageSquare } from 'react-icons/fi'
import { HiOutlineLink } from 'react-icons/hi'
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdDelete,
  MdOutlineLocationOn,
  MdWorkOutline
} from 'react-icons/md'
import { RiMessage3Line } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import { includeComma } from '../../../services/global/globalFunction'
import IconButton from '../../ui-component/buttons/IconButton'
import ConfirmationModal from '../../ui-component/modal/ConfirmationModal'
import { ArrowTooltip } from '../../ui-component/tooltip/Tooltip'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import RaiseIssueModal from '../job-listing/RaiseIssueModal'
import SendMailModal from '../job-listing/SendMailModal'
import ViewMessageModal from './ViewMessageModal'

const SingleShortlistUser = ({
  searchedUsers,
  credits,
  refetchData,
  data,
  idx,
  lastIdx = false,
  fetchCreditData,
  usersToMessage,
  setUsersToMessage,
  sendMultipleMessage
}) => {
  const {
    email,
    github_id,
    activity_date,
    message,
    message_title,
    total_repos: repositories,
    followers,
    location,
    following,

    tag,
    name,
    experience,
    avatar_url,
    username
  } = data

  const [isLoading, setIsLoading] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const { shortlistEndpoint } = EndPoints.dashboard
  const { searchUserEndpoint } = EndPoints.searchPage
  const [showMailModal, setShowMailModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showIssueModal, setShowIssueModal] = useState(false)
  const [selectedForMessage, setSelectedForMessage] = useState(
    usersToMessage.find((user) => user.github_id === github_id)
  )

  const elapsedTime = (date) => {
    if (date === null || date === '') return 2
    const prev_date = new Date(date)
    const curr_date = new Date()

    return (curr_date - prev_date) / (1000 * 60 * 60 * 24)
  }

  const deleteShortlisted = async () => {
    try {
      await axiosInstance.delete(`${shortlistEndpoint}/Dev/shortlist`, {
        data: {
          user_id: user.user_id,
          github_id
        }
      })
      refetchData()
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleMessageSelect = () => {
    if (credits <= usersToMessage.length) {
      showToast('error', 'Not enough credits')

      return
    }
    if (usersToMessage.find((user) => user.github_id === github_id)) {
      const filteredUser = usersToMessage.filter(
        (user) => user.github_id !== github_id
      )
      setUsersToMessage(filteredUser)
      setSelectedForMessage(false)
    } else {
      setUsersToMessage((prev) => [...prev, { email, github_id }])
      setSelectedForMessage(true)
    }
  }

  return (
    <tr className="relative text-xs font-semibold text-gray-600 transition-colors border-b cursor-pointer xl:text-sm font-600 hover:bg-indigo-50/50 ">
      <td>
        <div className="flex items-center justify-center gap-2">
          {sendMultipleMessage && (
            <label className="grid place-items-center">
              <button
                disabled={elapsedTime(activity_date) < 1}
                className="grid text-2xl place-items-center disabled:cursor-not-allowed disabled:text-gray-300 "
                onClick={handleMessageSelect}
              >
                {selectedForMessage ? (
                  <MdCheckBox />
                ) : (
                  <MdCheckBoxOutlineBlank />
                )}
              </button>
            </label>
          )}
          {!sendMultipleMessage && <p className="text-center">{idx + 1}</p>}
        </div>
      </td>
      <td>
        <div className="flex items-center px-4 space-x-3 min-w-[250px]">
          <img
            className="object-contain w-10 h-10 rounded-full"
            src={avatar_url}
            alt={github_id}
          />

          <Link
            to={`/dashboard/${github_id}`}
            className="flex flex-col gap-1 px-4 cursor-pointer"
          >
            <div className="font-semibold">{name}</div>
            <div className="text-sm text-indigo-500">@{github_id}</div>
          </Link>
        </div>
      </td>

      <td>
        <p className="p-2 px-4 ">{includeComma(followers)}</p>
      </td>
      <td>
        <p className="p-2 px-4 ">{following} </p>
      </td>
      <td className="min-w-[150px]">
        <p className="p-2 px-4 ">{repositories} </p>
      </td>
      <td>
        <div className="p-2 px-4 ">
          <div className="flex items-center gap-2 p-1 px-2 text-indigo-500 rounded-full bg-indigo-50 max-w-max">
            {tag}
          </div>
        </div>
      </td>
      <td>
        <div className="p-2 px-4 min-w-[200px]">
          <div className="flex items-center gap-2 ">
            <MdOutlineLocationOn size={20} />
            <p className="flex-1">{location}</p>
          </div>
        </div>
      </td>
      <td>
        <div className="p-2 px-4 min-w-[150px]">
          <div className="flex items-center gap-2 ">
            <MdWorkOutline size={20} />
            <p className="flex-1">
              {experience} {experience > 1 ? 'Years' : 'Year'}
            </p>
          </div>
        </div>
      </td>
      <td className="relative text-center">
        <div className="flex flex-col">
          <div className="flex gap-2 items-center w-[180px] m-auto">
            <div>
              {email !== null && email !== '' ? (
                <IconButton
                  toolTipTitle={`Send mail to ${email}`}
                  text={'Send Message'}
                  icon={<RiMessage3Line />}
                  onClick={() => setShowMailModal(true)}
                  type="primary"
                />
              ) : (
                <IconButton
                  toolTipTitle={`connect with ${github_id}`}
                  text={'Connect'}
                  onClick={() => setShowIssueModal(true)}
                  icon={<HiOutlineLink />}
                  type="dark"
                />
              )}
            </div>

            {activity_date !== null && message && (
              <Tooltip title={'Last sent Message'}>
                <button
                  onClick={() => setShowMessage(true)}
                  className="text-lg text-gray-600 "
                >
                  <FiMessageSquare />
                </button>
              </Tooltip>
            )}
          </div>
          {activity_date && (
            <p className="mt-2 text-xs">
              last connected :{' '}
              {activity_date.split(' ')[0].split('-').reverse().join('/') +
                ' ' +
                activity_date.split(' ')[1]}
            </p>
          )}
        </div>
        {showMessage && (
          <div className="fixed top-0 left-0 right-0 z-10 grid w-full h-screen text-left bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm place-items-center">
            <div className="z-50 w-full max-w-3xl p-4 m-auto">
              <ClickWrapper func={() => setShowMessage(false)}>
                <ViewMessageModal
                  close={() => setShowMessage(false)}
                  message={message}
                  title={message_title}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
        {showMailModal && (
          <div className="fixed top-0 left-0 right-0 z-10 grid w-full h-screen text-left bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm place-items-center">
            <div className="z-50 w-full max-w-2xl p-4 m-auto">
              <ClickWrapper func={() => setShowMailModal(false)}>
                <SendMailModal
                  recieverDetails={[{ email, github_id }]}
                  refetchData={() => {
                    fetchCreditData()
                    refetchData()
                  }}
                  close={() => setShowMailModal(false)}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
        {showIssueModal && (
          <div className="fixed top-0 left-0 right-0 z-10 grid w-full h-screen text-left bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm place-items-center">
            <div className="z-50 w-full max-w-2xl p-4 m-auto">
              <ClickWrapper func={() => setShowIssueModal(false)}>
                <RaiseIssueModal
                  refetchData={() => {
                    fetchCreditData()
                    refetchData()
                  }}
                  close={() => setShowIssueModal(false)}
                  githubid={github_id}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
      </td>
      <td>
        <div className="grid place-items-center">
          <ArrowTooltip title={'Remove from Shortlist'}>
            <button onClick={() => setShowConfirmationModal(true)}>
              <MdDelete className="text-xl text-red-500" />
            </button>
          </ArrowTooltip>
        </div>
        {showConfirmationModal && (
          <div className="fixed top-0 left-0 right-0 z-10 grid w-full h-screen bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm place-items-center">
            <div className="z-50 w-full max-w-md p-6 m-auto">
              <ClickWrapper func={() => setShowConfirmationModal(false)}>
                <ConfirmationModal
                  heading={`Remove Shortlist`}
                  title={`Are you sure you want to remove ${github_id} from shortlist ?`}
                  positiveText={'Delete'}
                  negativeText={'Cancel'}
                  handlePositive={deleteShortlisted}
                  handleNegative={() => setShowConfirmationModal(false)}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
      </td>
    </tr>
  )
}

export default SingleShortlistUser
