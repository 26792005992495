import React from 'react'
import { HiLocationMarker } from 'react-icons/hi'
import { MdLocalPhone } from 'react-icons/md'
import { GrMail } from 'react-icons/gr'
import { Helmet } from 'react-helmet'
import uuid from 'react-uuid'
import { footerData } from '../../components/footer/footerData'
import ContactForm from '../../components/contact/ContactForm'

const ContactPage = () => {
  return (
    <div className="flex w-full min-h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>TechProfile | Contact Us</title>
      </Helmet>
      <div className="relative flex-col justify-start hidden max-w-md gap-10 p-10 overflow-hidden  xl:flex bg-gradient-to-t from-blue-200 to-transparent bg-purple-darker">
        <div className="flex flex-col gap-6">
          {' '}
          <h1 className="text-3xl font-semibold ">Contact Information</h1>
          <p className="text-gray-500">
            Fill up the form and out team wil get back to you within 24 hrs{' '}
          </p>
        </div>

        <div className="flex flex-col gap-6">
          <div className="flex items-center gap-3">
            <MdLocalPhone />
            <p className="text-sm">+44 9639862853</p>
          </div>
          <div className="flex items-center gap-3">
            <GrMail />
            <p className="text-sm">Customer@Techprofile.org</p>
          </div>
          <div className="flex items-center gap-3">
            <HiLocationMarker />
            <p className="text-sm">71-75 Shelton Street, London , WC2H 9JQ</p>
          </div>
        </div>

        <ul className="z-30 flex items-center gap-3 ">
          {footerData.map((footer) => {
            footer.link !== '#' && (
              <li key={uuid()}>
                <a href={footer.link} target="blank">
                  {footer.component}
                </a>
              </li>
            )
          })}
        </ul>

        <lottie-player
          src="https://assets10.lottiefiles.com/packages/lf20_u25cckyh.json"
          background="transparent"
          speed="1"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            margin: 'auto'
          }}
          loop
          autoplay
        ></lottie-player>
      </div>

      <div className="flex flex-col items-center flex-1 w-full p-4 text-center bg-white  sm:p-6 md:p-10 font-600">
        <ContactForm />
      </div>
    </div>
  )
}

export default ContactPage
