import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axiosInstance from '../../../services/axios/axios'
import AgencyApplicants from '../../components/agency/AgencyApplicants'
import RecruiterInterview from '../../components/interviews/RecruiterInterview'
import PartnerJob from '../../components/job-listing/PartnerJob'
import RippleLoader from '../../components/loader/RippleLoader'
import JobDashboard from '../../components/recruiter-dashboard/JobDashboard'
import ProjectDashboard from '../../components/recruiter-dashboard/ProjectDashboard'

const RecruiterDashboard = () => {
  const [loading, setLoading] = useState(true)
  const [recruiterJobData, setRecruiterJobData] = useState({})
  const [recruiterProjectData, setRecruiterProjectData] = useState({})
  const { user } = useSelector((state) => state.auth)

  const tabs = [
    {
      name: 'Your Jobs',
      component: <JobDashboard recruiterData={recruiterJobData} />
    },
    {
      name: 'Your Projects',
      component: <ProjectDashboard recruiterData={recruiterProjectData} />,
      hidden: user.type === 'AGENCY'
    },
    {
      name: 'Partnered Jobs',
      component: <PartnerJob />,
      hidden: user.type !== 'AGENCY'
    },
    {
      name: 'Applicants',
      component: <AgencyApplicants />,
      hidden: user.type !== 'AGENCY'
    },
    { name: 'Upcoming Interviews', component: <RecruiterInterview /> }
  ]

  const fetchrecruiterDashboardData = async () => {
    try {
      const res = await Promise.all([
        axiosInstance.get(
          `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/getrecruiterjobs?user_id=${user.user_id}`
        ),
        axiosInstance.get(
          `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/getrecruiterprojects?user_id=${user.user_id}`
        )
      ])
      const parsedData = res.map((res) => res.data)
      setRecruiterJobData({
        applicants: parsedData[0].applicants,
        connections: parsedData[0].user_connections,
        jobs: parsedData[0].message
      })
      setRecruiterProjectData({
        applicants: parsedData[1].applicants,
        connections: parsedData[1].user_connections,
        projects: parsedData[1].message
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchrecruiterDashboardData()
  }, [])

  return (
    <div className="flex flex-col min-h-screen gap-10 p-4 sm:p-6 md:p-10">
      {loading ? (
        <RippleLoader />
      ) : (
        <TabGroup>
          <TabList className="flex gap-4 p-2 bg-gray-100 rounded-lg max-w-max">
            {tabs
              .filter((tab) => !tab.hidden)
              .map(({ name }) => (
                <Tab
                  key={name}
                  className="rounded-lg py-1 data-[selected]:shadow px-3 text-sm/6 font-semibold text-gray-500 data-[selected]:text-black focus:outline-none data-[selected]:bg-white data-[selected]:data-[hover]:bg-gray-50 data-[focus]:outline-1 data-[focus]:outline-white"
                >
                  {name}
                </Tab>
              ))}
          </TabList>

          <TabPanels className="mt-3">
            {tabs
              .filter((tab) => !tab.hidden)
              .map(({ name, component }) => (
                <TabPanel key={name} className="p-3 rounded-xl bg-white/5">
                  {component}
                </TabPanel>
              ))}
          </TabPanels>
        </TabGroup>
      )}
    </div>
  )
}

export default RecruiterDashboard
