import { Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import HtmlParser from 'react-html-parser'
import { FiMessageSquare } from 'react-icons/fi'
import { HiOutlineLink } from 'react-icons/hi'
import { RiMessage3Line } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import EndPoints from '../../../../services/api/api'
import axiosInstance from '../../../../services/axios/axios'
import { includeComma } from '../../../../services/global/globalFunction'
import IconButton from '../../../ui-component/buttons/IconButton'
import ClickWrapper from '../../click-wrapper/ClickWrapper'
import RaiseIssueModal from '../../job-listing/RaiseIssueModal'
import SendMailModal from '../../job-listing/SendMailModal'
import { IoIosCode } from 'react-icons/io'
import { MdOutlineLocationOn, MdWorkOutline } from 'react-icons/md'

const SingleAdvanceUser = ({
  searchedUsers,
  data,
  idx,
  lastIdx = false,
  fetchCreditData,
  refetchUserData
}) => {
  const {
    id,
    email,
    github_id,
    search_query,
    search_date,
    message_title,
    message,
    repositories,
    followers,
    location,
    following,
    createdAt,
    language,
    sponsors,
    updatedAt,
    experience,
    avatar_url,
    username,
    activity_date
  } = data

  const [isLoading, setIsLoading] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const { dashboardEndpoint } = EndPoints.dashboard
  const { searchUserEndpoint } = EndPoints.searchPage
  const [showMailModal, setShowMailModal] = useState(false)
  const [showIssueModal, setShowIssueModal] = useState(false)

  const elapsedTime = (date) => {
    if (date === null || date === '') return 2
    const prev_date = new Date(date)
    const curr_date = new Date()

    return (curr_date - prev_date) / (1000 * 60 * 60 * 24)
  }

  const sendSearchData = async () => {
    const postData = {
      user_id: user?.user_id,
      activity: 'SEARCH',
      search_id: github_id,
      credit_used: 1
    }

    try {
      const { data } = await axiosInstance.post(
        `${searchUserEndpoint}/postactivity`,
        postData
      )
      console.log(JSON.parse(data.body))
    } catch (err) {
      console.log(err.message)
    }
  }
  return (
    <tr
      onClick={() => sendSearchData()}
      className="relative text-xs font-semibold text-gray-600 transition-colors border-b cursor-pointer xl:text-sm font-600 hover:bg-indigo-50/50 "
    >
      <td>
        <p className="text-center">{idx + 1}</p>
      </td>
      <td>
        <div className="flex items-center px-4 space-x-3 min-w-[250px]">
          <img
            className="object-contain w-10 h-10 rounded-full"
            src={avatar_url}
            alt={github_id}
          />

          <Link
            to={`/dashboard/${github_id}`}
            className="flex flex-col gap-1 px-4 cursor-pointer"
          >
            <div className="font-semibold">{username}</div>
            <div className="text-sm text-indigo-500">@{github_id}</div>
          </Link>
        </div>
      </td>

      <td>
        <p className="p-2 px-4 ">{includeComma(followers)}</p>
      </td>
      <td>
        <p className="p-2 px-4 ">{following} </p>
      </td>
      <td className="min-w-[150px]">
        <p className="p-2 px-4 ">{repositories} </p>
      </td>
      <td>
        <div className="p-2 px-4 ">
          <div className="flex items-center gap-2 p-1 px-2 text-indigo-500 rounded-full bg-indigo-50 max-w-max">
            <IoIosCode size={20} />
            {language}
          </div>
        </div>
      </td>
      <td>
        <div className="p-2 px-4 min-w-[200px]">
          <div className="flex items-center gap-2 ">
            <MdOutlineLocationOn size={20} />
            <p className="flex-1">{location}</p>
          </div>
        </div>
      </td>
      <td>
        <div className="p-2 px-4 min-w-[150px]">
          <div className="flex items-center gap-2 ">
            <MdWorkOutline size={20} />
            <p className="flex-1">
              {experience} {experience > 1 ? 'Years' : 'Year'}
            </p>
          </div>
        </div>
      </td>
      <td className="relative text-center">
        <div className="flex flex-col">
          <div className="flex gap-2 items-center w-[180px] m-auto">
            <div>
              {email !== null && email !== '' ? (
                <IconButton
                  toolTipTitle={`Send mail to ${email}`}
                  text={'Send Message'}
                  icon={<RiMessage3Line />}
                  onClick={() => setShowMailModal(true)}
                  type="primary"
                />
              ) : (
                <IconButton
                  toolTipTitle={`connect with ${github_id}`}
                  text={'Connect'}
                  onClick={() => setShowIssueModal(true)}
                  icon={<HiOutlineLink />}
                  type="dark"
                />
              )}
            </div>

            {activity_date !== null && message && (
              <Tooltip title={'Last sent Message'}>
                <button
                  onClick={() => setShowMessage(true)}
                  className="text-lg text-gray-600 "
                >
                  <FiMessageSquare />
                </button>
              </Tooltip>
            )}
          </div>
          {activity_date && (
            <p className="mt-2 text-xs">
              last connected :{' '}
              {activity_date.split(' ')[0].split('-').reverse().join('/') +
                ' ' +
                activity_date.split(' ')[1]}
            </p>
          )}
        </div>
        {showMessage && (
          <ClickWrapper func={() => setShowMessage(false)}>
            <div
              className={`flex flex-col gap-2 p-3 text-sm bg-gray-100 z-30 absolute  ${
                lastIdx ? 'bottom-0' : 'top-10'
              }  rounded-md left-0 w-[90%] border border-gray-400 border-opacity-30 text-left shadow-sm`}
            >
              <p className="pb-3 text-xs border-b border-gray-400 border-opacity-30">
                <span className="font-bold">Title :</span> {message_title}
              </p>
              <p className="w-full text-sm break-words">
                {' '}
                <span className="font-semibold ">Message :</span>{' '}
                {HtmlParser(message)}
              </p>
            </div>
          </ClickWrapper>
        )}
        {showMailModal && (
          <div className="fixed top-0 left-0 right-0 z-10 grid w-full h-screen text-left bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm place-items-center">
            <div className="z-50 w-full max-w-2xl p-6 m-auto">
              <ClickWrapper func={() => setShowMailModal(false)}>
                <SendMailModal
                  recieverDetails={[{ email, github_id }]}
                  refetchData={() => {
                    fetchCreditData()
                    refetchUserData()
                  }}
                  close={() => setShowMailModal(false)}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
        {showIssueModal && (
          <div className="fixed top-0 left-0 right-0 z-10 grid w-full h-screen text-left bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm place-items-center">
            <div className="z-50 w-full max-w-2xl p-4 m-auto">
              <ClickWrapper func={() => setShowIssueModal(false)}>
                <RaiseIssueModal
                  refetchData={() => {
                    fetchCreditData()
                    refetchUserData()
                  }}
                  close={() => setShowIssueModal(false)}
                  githubid={github_id}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
      </td>
    </tr>
  )
}

export default SingleAdvanceUser
