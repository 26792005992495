import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'

const SingleRecruiterProjectCard = ({ data }) => {
  const {
    company_logo,
    create_date,
    description,
    project_id,
    project_title,
    key_skills,
    total_applicant
  } = data

  const navigate = useNavigate()

  return (
    <div
      onClick={() => navigate(`/project-listing/${project_id}`)}
      className="flex flex-col p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow min-h-[280px] cursor-pointer border border-gray-200"
    >
      {/* Project Header */}
      <div className="flex items-center gap-4 mb-4">
        {company_logo && company_logo !== '' && (
          <img
            className="object-contain w-12 h-12 rounded-lg"
            src={company_logo}
            alt={project_title}
          />
        )}
        <div>
          <h2 className="text-lg font-semibold text-gray-900">
            {project_title}
          </h2>
          <p className="text-sm text-gray-500">
            {create_date.split(' ')[0].split('-').reverse().join('/')}
          </p>
        </div>
      </div>

      {/* Project Description */}
      <div className="mb-4">
        <p className="font-semibold text-gray-700">Description:</p>
        <p className="text-sm text-gray-600 line-clamp-2">
          {ReactHtmlParser(description)}
        </p>
      </div>

      {/* Skills */}
      {key_skills?.length > 0 && (
        <div className="mb-4">
          <p className="font-semibold text-gray-700">Skills:</p>
          <div className="flex flex-wrap gap-2">
            {key_skills &&
              key_skills.split('|').map((skill) => (
                <span
                  key={uuid()}
                  className="px-3 py-1 text-xs font-medium text-gray-700 bg-gray-100 rounded-full shadow"
                >
                  {skill}
                </span>
              ))}
          </div>
        </div>
      )}

      {/* Footer with Applicants and Project ID */}
      <div className="flex items-center justify-between mt-auto">
        <p className="text-sm font-bold text-gray-600">
          Applicants: {total_applicant}
        </p>
        <p className="text-xs text-gray-500">Project ID: {project_id}</p>
      </div>
    </div>
  )
}

export default SingleRecruiterProjectCard
