import React, { Fragment, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { BiLoaderAlt } from 'react-icons/bi'
import { GiMoneyStack } from 'react-icons/gi'
import { IoLocationOutline, IoMedalOutline } from 'react-icons/io5'
import {
  MdAttachFile,
  MdOutlineDone,
  MdReport,
  MdWorkOutline
} from 'react-icons/md'
import ReactQuill from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import uuid from 'react-uuid'
import { HOME_PAGE } from '../../../navigation/routes'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import ClickWrapper from '../../components/click-wrapper/ClickWrapper'
import EmailInput from '../../components/create-profile/email-input/EmailInput'
import GithubVerification from '../../components/create-profile/github-verification/GithubVerification'
import GreetingCard from '../../components/create-profile/greeting/GreetingCard'
import NumberInput from '../../components/create-profile/num-input/NumberInput'
import ReapplyModal from '../../components/create-profile/reapply/ReapplyModal'
import TextInput from '../../components/create-profile/text-input/TextInput'
import RippleLoader from '../../components/loader/RippleLoader'

const DirectApplyPage = () => {
  const { jobId } = useParams()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [tnc, setTnc] = useState(false)
  const { user, githubUser } = useSelector((state) => state.auth)
  const [isGithubVerified, setIsGithubVerified] = useState(
    githubUser ? true : false
  )
  const [allSkills, setAllSkills] = useState([])
  const [currentJobData, setCurrentJobData] = useState({})
  const [showMessage, setshowMessage] = useState(false)
  const [showGreetings, setShowGreetings] = useState(false)
  const [imageFile, setImageFile] = useState()
  const [fileName, setFileName] = useState('')
  const [imageUploading, setImageUploading] = useState(false)
  const [imageUploaded, setImageUploaded] = useState(false)
  const { checkJobExist, createProfile, getAllSkills } = EndPoints.createProfile
  const { uploadJobLogo } = EndPoints.createJob
  const [isReadMore, setIsReadmore] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [profileValue, setProfileValue] = useState({
    name: '',
    email: '',
    dob: '',
    phone_number: '',
    cover_letter: '',
    experience: 0,
    linkdin: '',
    portfolio: '',
    github: githubUser ? githubUser.username : ''
  })

  const [showReplyModal, setShowReplyModal] = useState(false)

  const checkWhetherJobExist = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${checkJobExist}/getjobstatus?job_id=${jobId}`
      )
      if (data.body === 'null') {
        // navigate('*');
        // return;
        setshowMessage(true)
      } else {
        const status = JSON.parse(data.body)?.status
        if (status.toLowerCase() !== 'open') {
          navigate(HOME_PAGE)
          return
        }
        fetchAllSkills()
        fetchJobDetails()
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchJobDetails = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${checkJobExist}/getjobdetails?job_id=${jobId}`
      )
      // if (JSON.parse(data.body.message).length === 0) {
      // 	setshowMessage(true);
      // }
      setCurrentJobData(JSON.parse(data.body.message)[0])
      setIsLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    checkWhetherJobExist()
  }, [jobId])

  const handleCreateProfile = async (postData) => {
    if (
      !isGithubVerified &&
      currentJobData.organization.toLowerCase() !== 'nykaa'
    ) {
      showToast('warn', 'Verify Your Github')
      return
    }

    try {
      await uploadImage()
      const { data } = await axiosInstance.post(`${createProfile}`, {
        ...postData,
        resume: fileName
      })
      if (data.message === 'already applied') {
        setShowReplyModal(true)
        return
      }
      //console.log(JSON.parse(data.body));
      showToast('success', JSON.parse(data.body))

      setShowGreetings(true)
      setTimeout(() => {
        navigate(HOME_PAGE)
      }, 5000)
    } catch (err) {
      console.log(err.message)
      showToast('error', err.message)
    }
  }

  const fileChange = (e) => {
    console.log(e.target.files[0])
    if (!e.target.files[0]) {
      alert('please Select a valid file')
      return
    }
    const newFile = e.target.files[0]
    setImageFile(newFile)
    setFileName(`${jobId}-${uuid()}-${newFile.name.split(' ').join('')}`.trim())
  }

  const uploadImage = async () => {
    console.log(imageFile)
    if (!imageFile) return

    setImageUploading(true)
    const options = {
      headers: {
        //Authorization: `Bearer ${user.token}`,
        'Content-Type': imageFile.type
      }
    }

    try {
      const { data } = await axiosInstance.post(`${uploadJobLogo}`, {
        filename: fileName,
        bucketname: 'github-cv'
      })

      const uploadUrl = JSON.parse(data).data.URL
      console.log(uploadUrl)
      const res = await axiosInstance.put(uploadUrl, imageFile, options)
      console.log(res)
      setImageFile(undefined)
    } catch (err) {
      console.log(err.message)
    }
    setImageUploading(false)
    setImageUploaded(true)
  }

  const fetchAllSkills = async () => {
    try {
      const { data } = await axiosInstance.get(`${getAllSkills}`)
      //console.log(data);
      setAllSkills(JSON.parse(data.body))
    } catch (err) {
      console.log(err.message)
    }
  }
  const reapplyJobApplication = (e) => {
    e.preventDefault()
    setShowReplyModal(false)
    handleDirectApply(true)
  }

  const handleDirectApply = (reapply = false) => {
    handleCreateProfile({
      name: profileValue.name,
      email: profileValue.email,
      phone_number: profileValue.phone_number,
      date_of_birth: profileValue.dob,
      // key_skills: profileValue?.keySkills.map(
      // 	(skill) => skill.language_name,
      // ),
      linkdin: profileValue.linkdin,
      portfolio: profileValue.portfolio,
      cover_letter: profileValue.cover_letter,
      experience: profileValue.experience,
      reapply,
      github_profile: profileValue.github,
      hid: jobId.trim()
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    handleDirectApply()
  }

  return (
    <div className="relative min-h-screen p-2 sm:p-6 md:p-10 ">
      {showMessage ? (
        <div className="grid place-items-center ">
          <div className="w-full max-w-2xl p-6 bg-white rounded-md shadow-lg">
            <h1 className="text-2xl text-center ">
              This Job is no longer available
            </h1>
          </div>
        </div>
      ) : (
        <Fragment>
          {showGreetings ? (
            <GreetingCard />
          ) : (
            <Fragment>
              {isLoading ? (
                <RippleLoader />
              ) : (
                <div className="w-full max-w-4xl m-auto overflow-hidden border border-gray-400 shadow-outer border-opacity-30 rounded-xl">
                  <div className="px-4 py-5 bg-gray-900 sm:px-6">
                    <div className="flex items-center justify-between">
                      <div>
                        <h2 className="text-2xl font-bold text-white">
                          {' '}
                          {currentJobData.organization}
                        </h2>
                        <p className="mt-1 text-sm text-gray-300">
                          {currentJobData.job_name}
                        </p>
                      </div>
                      {currentJobData.company_logo && (
                        <img
                          className="object-contain w-12 h-12 rounded-md"
                          src={currentJobData.company_logo}
                          alt={''}
                        />
                      )}
                    </div>
                  </div>
                  <div className="p-3 bg-white sm:p-6">
                    <div className="flex flex-col w-full gap-2 text-sm">
                      {currentJobData.key_skills && (
                        <div className="flex flex-wrap items-center gap-2 my-2">
                          {currentJobData.key_skills && (
                            <div className="w-full ">
                              <p className="mb-2 font-semibold md:text-xl">
                                Skills Required :{' '}
                              </p>
                              <div className="flex flex-wrap items-center gap-2">
                                {currentJobData.key_skills
                                  .split(',')
                                  .map((skills) => (
                                    <p
                                      key={uuid()}
                                      className="p-1.5 px-3 text-xs text-gray-800 bg-gray-100 rounded-sm shadow "
                                    >
                                      {' '}
                                      {skills}
                                    </p>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="flex flex-wrap gap-4 mt-6 mb-6 text-sm text-muted-foreground">
                        <div className="flex items-center">
                          <MdWorkOutline className="w-4 h-4 mr-2" />
                          {currentJobData.years_of_experience}{' '}
                          {currentJobData.years_of_experience > 1
                            ? 'years'
                            : 'year'}{' '}
                          of experience{' '}
                        </div>
                        {currentJobData.job_type && (
                          <div className="flex items-center">
                            <IoMedalOutline className="w-4 h-4 mr-2" />
                            {currentJobData.job_type}
                          </div>
                        )}
                        {currentJobData.pay_range && (
                          <div className="flex items-center">
                            <GiMoneyStack className="w-4 h-4 mr-2" />
                            {currentJobData.pay_range}
                          </div>
                        )}
                        {currentJobData.weekly_hours && (
                          <div className="flex items-center">
                            <AiOutlineClockCircle className="w-4 h-4 mr-2" />
                            {currentJobData.weekly_hours} hours
                          </div>
                        )}
                        {currentJobData.work_hours && (
                          <div className="flex items-center">
                            <IoLocationOutline className="w-4 h-4 mr-2" />
                            {currentJobData.work_hours}
                          </div>
                        )}
                      </div>
                      {currentJobData.description && (
                        <div className="mb-6">
                          <h3 className="text-lg font-medium text-gray-900">
                            Job Description
                          </h3>
                          <p
                            className={`${
                              isReadMore ? 'line-clamp-2' : ''
                            } text-sm `}
                          >
                            {ReactHtmlParser(
                              !isReadMore
                                ? currentJobData.description
                                : currentJobData.description.slice(0, 200)
                            )}
                          </p>
                          {currentJobData.description.toString().split(' ')
                            .length > 15 && (
                            <button
                              onClick={() => setIsReadmore(!isReadMore)}
                              className="text-sm font-semibold text-blue-400"
                            >
                              {!isReadMore ? 'Read less' : 'Read More'}
                            </button>
                          )}
                        </div>
                      )}
                      {currentJobData.organization.toLowerCase() !==
                        'nykaa' && (
                        <p className="flex items-center justify-center gap-2 mb-3 text-sm text-center text-yellow-500">
                          <MdReport />
                          You have to<strong>Login</strong> after applying and
                          verify the job application to be verified .
                        </p>
                      )}
                      <form
                        onSubmit={onSubmit}
                        className="flex flex-col w-full gap-6 mt-6 md:gap-10"
                      >
                        <div className="flex flex-col gap-4 sm:grid sm:grid-cols-2 md:gap-6 ">
                          <TextInput
                            value={profileValue.name}
                            setValue={(val) =>
                              setProfileValue({ ...profileValue, name: val })
                            }
                            label={'Name'}
                            placeholder={'Enter Your Name'}
                            required={true}
                          />
                          <EmailInput
                            value={profileValue.email}
                            setValue={(val) =>
                              setProfileValue({ ...profileValue, email: val })
                            }
                            label={'Email'}
                            placeholder={'Enter Your Email'}
                            required={true}
                          />
                          <NumberInput
                            value={profileValue.experience}
                            setValue={(val) =>
                              setProfileValue({
                                ...profileValue,
                                experience: val
                              })
                            }
                            label={'Years of Experience'}
                            placeholder={'Years of Experience'}
                            required={true}
                            min={0}
                          />

                          <TextInput
                            value={profileValue.phone_number}
                            setValue={(val) =>
                              setProfileValue({
                                ...profileValue,
                                phone_number: val
                              })
                            }
                            label={'Phone'}
                            placeholder={'Enter Your contact number '}
                            allowNumber
                          />
                          <TextInput
                            value={profileValue.linkdin}
                            setValue={(val) =>
                              setProfileValue({
                                ...profileValue,
                                linkdin: val
                              })
                            }
                            label={'Linkedin url'}
                            placeholder={'Enter Your linkedin profile url '}
                          />
                          <TextInput
                            value={profileValue.portfolio}
                            setValue={(val) =>
                              setProfileValue({
                                ...profileValue,
                                portfolio: val
                              })
                            }
                            label={'Portfolio'}
                            placeholder={'Enter personal portfolio link '}
                          />

                          {currentJobData.organization.toLowerCase() !==
                            'nykaa' && (
                            <GithubVerification
                              value={profileValue.github}
                              setValue={(val) =>
                                setProfileValue({
                                  ...profileValue,
                                  github: val
                                })
                              }
                              isGithubVerified={isGithubVerified}
                              setIsGithubVerified={(val) =>
                                setIsGithubVerified(val)
                              }
                            />
                          )}

                          <label className="flex flex-col col-span-2 gap-2 mb-6 text-xs md:text-sm sm:mb-3 md:mb-0 ">
                            <p className="font-semibold ">Cover Letter</p>

                            <div className="h-40 bg-white">
                              <ReactQuill
                                className="h-32 "
                                placeholder="Write Cover Letter"
                                value={profileValue.cover_letter}
                                onChange={(val) =>
                                  setProfileValue({
                                    ...profileValue,
                                    cover_letter: val
                                  })
                                }
                              />
                            </div>
                          </label>
                          <label className="flex flex-col col-span-2 gap-2 mb-6 text-xs md:text-sm sm:mb-3 md:mb-0 ">
                            <p className="font-semibold ">Upload Resume</p>
                            <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md">
                              <div className="space-y-1 text-center">
                                <div className="flex gap-2 items-center justify-center w-[200px]">
                                  <div
                                    className={`flex  cursor-pointer   justify-center items-center text-sm font-semibold gap-2 p-[10px] disabled:bg-gray-400 px-`}
                                  >
                                    <MdAttachFile className="w-12 h-12 mx-auto text-gray-400" />
                                  </div>
                                  {imageUploading && (
                                    <BiLoaderAlt className="w-12 h-12 mx-auto text-gray-400 animate-spin" />
                                  )}
                                  {imageUploaded && (
                                    <MdOutlineDone className="w-12 h-12 mx-auto text-gray-400" />
                                  )}
                                </div>

                                <div className="flex flex-col items-center text-sm text-center text-gray-600">
                                  <label
                                    htmlFor="resume"
                                    className="relative font-medium bg-white rounded-md cursor-pointer text-primary hover:text-primary-dark focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary"
                                  >
                                    <span>Upload a file</span>
                                    <input
                                      type="file"
                                      className="hidden"
                                      onChange={fileChange}
                                      accept=".doc, .docx,.pdf"
                                    />
                                  </label>
                                  <p>{imageFile?.name}</p>
                                  <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs text-gray-500">
                                  PDF, DOC, DOCX up to 10MB
                                </p>
                              </div>
                            </div>
                          </label>
                        </div>

                        <div className="flex flex-col justify-between gap-3 ">
                          <label className="z-0 flex items-center gap-2 text-xs cursor-pointer font-600">
                            <input
                              value={tnc}
                              onChange={(e) => setTnc(e.target.checked)}
                              className="cursor-pointer "
                              type="checkbox"
                            />

                            <p>I Confirm the details provided are correct</p>
                          </label>

                          <button
                            disabled={!tnc}
                            className="w-full p-3 py-2.5 font-semibold col-span-2 text-center text-white bg-gray-900 rounded-lg"
                          >
                            Submit Application
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          )}
          {showReplyModal && (
            <div className="fixed top-0 left-0 right-0 z-10 grid w-full h-screen bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm place-items-center">
              <div className="z-50 w-full max-w-md p-6 m-auto">
                <ClickWrapper func={() => setShowReplyModal(false)}>
                  <ReapplyModal
                    title={
                      'You have already applied to this Job . Do you want to reapply ?'
                    }
                    positiveText={'Reapply'}
                    negativeText={'Cancel'}
                    handleNegative={() => setShowReplyModal(false)}
                    handlePositive={reapplyJobApplication}
                  />
                </ClickWrapper>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default DirectApplyPage
