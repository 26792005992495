import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from '../error/ErrorComponent'
import RecruiterChart from './charts/RecruiterCharts'
import RecruiterHeader from './header/RecruiterHeader'
import ProjectTable from './dashboard-table/ProjectCardList'
import RecruiterApplicantChart from './charts/ApplicationCharts'

const ProjectDashboard = ({ recruiterData }) => {
  return (
    <div className="flex flex-col gap-10">
      <ErrorBoundary FallbackComponent={ErrorComponent}>
        <RecruiterHeader data={recruiterData} label={'Projects'} />
      </ErrorBoundary>

      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        <ErrorBoundary FallbackComponent={ErrorComponent}>
          <RecruiterApplicantChart data={recruiterData} label="Projects" />
        </ErrorBoundary>
        <div className="rounded-xl shadow-custom">
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <RecruiterChart
              heading={'Projects Created'}
              chartData={{
                data: [
                  recruiterData.projects.filter(
                    (project) => project.status === 'OPEN'
                  ).length,
                  recruiterData.projects.length -
                    recruiterData.projects.filter(
                      (project) => project.status === 'OPEN'
                    ).length
                ],
                categories: ['Open Projects', 'Closed Projects']
              }}
            />
          </ErrorBoundary>
        </div>

        <div className="rounded-xl shadow-custom">
          {' '}
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <RecruiterChart
              heading={'Total Applications and Selections'}
              chartData={{
                data: [
                  Object.values(recruiterData.applicants).reduce(
                    (a, b) => a + b,
                    0
                  ),
                  recruiterData.applicants.JOINED +
                    recruiterData.applicants['AI SELECTED']
                ],
                categories: ['Total Applications', 'Total Selections']
              }}
            />
          </ErrorBoundary>
        </div>
      </div>
      <div className="rounded-xl shadow-custom">
        <ErrorBoundary FallbackComponent={ErrorComponent}>
          <RecruiterChart
            heading={'Applicants'}
            chartData={{
              data: Object.values(recruiterData.applicants),
              categories: Object.keys(recruiterData.applicants)
            }}
          />
        </ErrorBoundary>
      </div>
      <ErrorBoundary FallbackComponent={ErrorComponent}>
        <ProjectTable projectData={recruiterData?.projects ?? []} />
      </ErrorBoundary>
    </div>
  )
}

export default ProjectDashboard
