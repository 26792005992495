import React, { Fragment } from 'react'
import uuid from 'react-uuid'
import SingleRecruiterProjectCard from './SingleRecruiterProjectCard'
import { Link } from 'react-router-dom'

const ProjectCardList = ({ projectData = [] }) => {
  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-6">
        <h3 className="px-6 font-semibold truncate md:px-10">All Projects</h3>
      </div>

      {/* Grid layout for project cards */}
      <div className="grid grid-cols-1 gap-6 px-6 sm:grid-cols-2 lg:grid-cols-3 md:px-10">
        {projectData.length !== 0 ? (
          <Fragment>
            {projectData.slice(0, 9).map((singleProject) => (
              <SingleRecruiterProjectCard key={uuid()} data={singleProject} />
            ))}
            {projectData.length > 9 && (
              <div className="flex justify-center mt-4">
                <Link to="/project-listing" className="text-blue-500">
                  View All Projects
                </Link>
              </div>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <p className="w-full py-4 my-4 text-center text-gray-500 font-600">
              No Projects Found
            </p>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default ProjectCardList
