import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCreditHook from '../../../../hooks/useCreditHook'
import creditAction from '../../../../redux/actions/creditAction'
import EndPoints from '../../../../services/api/api'
import axiosInstance from '../../../../services/axios/axios'
import ClickWrapper from '../../click-wrapper/ClickWrapper'
import NoCreditComponent from '../../credits/NoCreditComponent'
import { Select } from '@headlessui/react'
import { HiChevronDown } from 'react-icons/hi'
import clsx from 'clsx'
import { MultiAsyncSelect } from '../../common/select/MultiAsyncSelect'

const AdvanceUserFormV2 = ({ setSearchedUser, setLoading }) => {
  const { searchAdvancedUser, getSearchResult } = EndPoints.searchPage
  const [selectedSkill, setSelectedSkill] = useState([])
  const [selectedCountry, setSelectedCountry] = useState([])
  const [selectedLocation, setSelectedLocation] = useState([])
  const [level, setLevel] = useState('')
  const [experience, setExperience] = useState('')
  const { user } = useSelector((state) => state.auth)
  const [showTokenModal, setShowTokenModal] = useState(false)
  const { searchCandidateEligibility } = useCreditHook()
  const dispatch = useDispatch()

  const getSearchQuery = (skill, country, location, level, experience) => {
    const skillQuery = skill === '' ? 'ALL' : skill
    const locationQuery =
      location === '' || location.length === 0 || location[0] === 'ALL'
        ? 'ALL'
        : `[${location.map((location) => `"${location}"`)}]`
    const countryQuery = country === '' ? 'ALL' : country
    const levelQuery = level === '' ? 'ALL' : level
    const experienceQuery = experience === '' ? 'ALL' : experience
    if (skill === '' && location === '' && level === '' && experience === '') {
      return searchAdvancedUser
    }
    return `${searchAdvancedUser}?language=${skillQuery}&country=${countryQuery}&city=${locationQuery}&level=${levelQuery}&experience=${experienceQuery}&user_id=${user.user_id}`
  }

  // Async functions to load options
  const fetchSkills = async (inputValue) => {
    console.log(inputValue, 'input value')
    if (inputValue === '') return []
    try {
      const { data } = await axiosInstance.get(
        `${getSearchResult}/getlanguage?language=${inputValue}`
      )
      return JSON.parse(data.body).message.map((skill) => ({
        value: skill.language,
        label: skill.language
      }))
    } catch (err) {
      console.log(err.message)
      return []
    }
  }

  const fetchCountry = async (inputValue) => {
    if (inputValue === '') return []
    try {
      const { data } = await axiosInstance.get(
        `${getSearchResult}/getcityandcountry?country=${inputValue}`
      )
      return JSON.parse(data.body).map((country) => ({
        value: country.countries,
        label: country.countries
      }))
    } catch (err) {
      console.log(err.message)
      return []
    }
  }

  const fetchLocation = async (inputValue) => {
    if (selectedCountry.length === 0 || inputValue === '') return []
    try {
      const { data } = await axiosInstance.get(
        `${getSearchResult}/getcityandcountry?country=${selectedCountry}&city=${inputValue}`
      )
      return JSON.parse(data.body).map((location) => ({
        value: location.cities,
        label: location.cities
      }))
    } catch (err) {
      console.log(err.message)
      return []
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!searchCandidateEligibility) {
      setShowTokenModal(true)
      return
    }
    fetchAdvancedSearchUsers()
  }

  const fetchAdvancedSearchUsers = async () => {
    setLoading(true)

    try {
      const { data } = await axiosInstance.get(
        getSearchQuery(
          selectedSkill,
          selectedCountry,
          selectedLocation,
          level,
          experience
        )
      )

      sessionStorage.setItem(
        'ADVANCED_USER_QUERY',
        JSON.stringify(
          getSearchQuery(
            selectedSkill,
            selectedCountry,
            selectedLocation,
            level,
            experience
          )
        )
      )

      setSearchedUser(JSON.parse(data.body).message)
      dispatch(creditAction.fetchUserCredits(user.user_id))
      sessionStorage.setItem(
        'ADVANCED_USER',
        JSON.stringify(JSON.parse(data.body).message)
      )
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="grid w-full grid-cols-1 gap-6 ">
        {/* MultiAsyncSelect for Skills */}
        <div className="relative flex flex-col gap-2">
          <label className="flex flex-col gap-2">
            <p className="text-sm font-semibold text-gray-700">Skills</p>
            <MultiAsyncSelect
              promiseOptions={fetchSkills}
              onChange={(selectedOptions) =>
                setSelectedSkill(selectedOptions.value)
              }
              values={selectedSkill}
            />
          </label>
        </div>
        <div>
          <label className="flex flex-col gap-2">
            <p className="text-sm font-semibold text-gray-700 ">Level</p>

            <div className="relative">
              <Select
                className={clsx(
                  ' block w-full appearance-none rounded border border-gray-300 bg-white/5 py-1.5 px-3 text-sm/6 ',
                  'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
                  '*:text-black'
                )}
                value={level}
                onChange={(e) => setLevel(e.target.value.trim())}
              >
                <option value=""> All</option>
                <option value="learner">Learner</option>
                <option value="coder">Coder</option>
                <option value="procoder"> Pro Coder</option>
                <option value="ninja"> Ninja</option>
              </Select>
              <HiChevronDown
                className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-white/60"
                aria-hidden="true"
              />
            </div>
          </label>
        </div>
        <div>
          <label className="flex flex-col gap-2">
            <p className="text-sm font-semibold text-gray-700 ">Experience</p>

            <div className="relative">
              <Select
                className={clsx(
                  ' block w-full appearance-none rounded border border-gray-300 bg-white/5 py-1.5 px-3 text-sm/6 ',
                  'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
                  '*:text-black'
                )}
                value={experience}
                onChange={(e) => setExperience(e.target.value.trim())}
              >
                <option value=""> All</option>
                <option value="fresher"> Fresher (0-3 years)</option>
                <option value="experience">Experienced (3-7 years)</option>
                <option value="expert"> Expert (7+ years)</option>
              </Select>
              <HiChevronDown
                className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-white/60"
                aria-hidden="true"
              />
            </div>
          </label>
        </div>

        {/* MultiAsyncSelect for Country */}
        <div className="relative flex flex-col gap-2">
          <label className="flex flex-col gap-2">
            <p className="text-sm font-semibold text-gray-700">Country</p>
            <MultiAsyncSelect
              promiseOptions={fetchCountry}
              onChange={(selectedOptions) => {
                setSelectedCountry(selectedOptions.value)
              }}
              values={selectedCountry}
            />
          </label>
        </div>

        {/* MultiAsyncSelect for Location */}
        {selectedCountry.length > 0 && (
          <div className="relative flex flex-col gap-2">
            <label className="flex flex-col gap-2">
              <p className="text-sm font-semibold text-gray-700">Location</p>
              <MultiAsyncSelect
                promiseOptions={fetchLocation}
                onChange={(selectedOptions) =>
                  setSelectedLocation(
                    selectedOptions.map((option) => option.value)
                  )
                }
                isMulti
                values={selectedLocation}
              />
            </label>
          </div>
        )}

        <div className="flex flex-col gap-2">
          <button
            className="p-3 text-xs font-semibold text-white transition duration-300 rounded-lg shadow-md bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 hover:shadow-lg"
            type="submit"
          >
            Search
          </button>
        </div>
      </form>

      {showTokenModal && (
        <div className="fixed top-0 left-0 right-0 z-10 grid w-full h-screen bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm place-items-center">
          <div className="z-50 w-full max-w-xl p-6 m-auto">
            <ClickWrapper func={() => setShowTokenModal(false)}>
              <NoCreditComponent />
            </ClickWrapper>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdvanceUserFormV2
