import React, { useState } from 'react'
import { IoAddCircle } from 'react-icons/io5'
import IconButton from '../../ui-component/buttons/IconButton'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import CreateProjectModal from '../project/CreateProjectModal'
import ProjectListingWrapper from './ProjectListingWrapper'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'

const ProjectListing = ({ fetchAllProjects, allProjectData }) => {
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false)
  const [tab, setTab] = useState(1)

  const tabs = [
    {
      name: 'Open Jobs',
      component: (
        <ProjectListingWrapper
          allJobData={allProjectData.filter((job) => job.status === 'OPEN')}
          fetchAllJobs={fetchAllProjects}
        />
      )
    },
    {
      name: 'Closed Jobs',
      component: (
        <ProjectListingWrapper
          allJobData={allProjectData.filter((job) => job.status === 'CLOSE')}
          fetchAllJobs={fetchAllProjects}
        />
      )
    }
  ]

  return (
    <TabGroup>
      <div className="flex flex-col-reverse items-end justify-between gap-3 m-auto mb-6 max-w-7xl sm:flex-row sm:items-center md:mb-10 ">
        <TabList className="flex gap-4 p-2 bg-gray-100 rounded-lg max-w-max">
          {tabs
            .filter((tab) => !tab.hidden)
            .map(({ name }) => (
              <Tab
                key={name}
                className="rounded-lg py-1 data-[selected]:shadow px-3 text-sm/6 font-semibold text-gray-500 data-[selected]:text-black focus:outline-none data-[selected]:bg-white data-[selected]:data-[hover]:bg-gray-50 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                {name}
              </Tab>
            ))}
        </TabList>

        <IconButton
          toolTipTitle={'Create Project'}
          onClick={() => setShowCreateProjectModal(true)}
          text={'Create Project'}
          icon={<IoAddCircle />}
          type="primary"
        />
      </div>

      {showCreateProjectModal && (
        <div className="fixed top-0 left-0 right-0 z-10 grid w-full h-screen text-left bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm place-items-center">
          <div className="z-50 w-full max-w-4xl p-4 m-auto">
            <ClickWrapper func={() => {}}>
              <CreateProjectModal
                refetchData={fetchAllProjects}
                close={() => setShowCreateProjectModal(false)}
              />
            </ClickWrapper>
          </div>
        </div>
      )}

      <TabPanels className="mt-3">
        {tabs
          .filter((tab) => !tab.hidden)
          .map(({ name, component }) => (
            <TabPanel key={name} className="p-3 rounded-xl bg-white/5">
              {component}
            </TabPanel>
          ))}
      </TabPanels>
    </TabGroup>
  )
}

export default ProjectListing
