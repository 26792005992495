import React from 'react'
import { MdDone } from 'react-icons/md'

export const pricingData = {
  startup: {
    title: 'Startup',
    price: '$100',

    details: [
      {
        title: 'TechProfile Credits per month',
        render: '50'
      },
      {
        title: 'Publish Jobs on the platform',
        render: '1'
      },
      {
        title: 'Detailed profiles',
        render: <MdDone />
      },
      {
        title: 'Developer ranking & code-based leaderboard view',
        render: <MdDone />
      },
      {
        title: 'Direct developer outreaches: inmails / license / month',
        render: '10'
      },
      {
        title: 'Developer TechProfile Downloads /month',
        render: '50'
      },
      {
        title:
          'Expanding focused talent pool to ensure additional relevant developers in your pipeline via Support',
        render: <MdDone />
      },
      {
        title: 'Support level provided',
        render: 'Email'
      }
    ]
  },
  growth: {
    title: 'Growth',
    price: '$300',

    details: [
      {
        title: 'TechProfile Credits per month',
        render: '500'
      },
      {
        title: 'Publish Jobs on the platform',
        render: '10'
      },
      {
        title: 'Detailed profiles',
        render: <MdDone />
      },

      {
        title: 'Developer ranking & code-based leaderboard view',
        render: <MdDone />
      },
      {
        title: 'Full evaluation of any Developer',
        render: <MdDone />
      },
      {
        title: 'Direct developer outreaches: inmails / license / month',
        render: '120'
      },
      {
        title: 'Developer TechProfile Downloads /month',
        render: 'Unlimited'
      },
      {
        title: 'Build Team for collobration',
        render: <MdDone />
      },
      {
        title: 'Guided Hiring TechProfile Partner',
        render: <MdDone />
      },
      {
        title: 'Employer branding for Job and application',
        render: <MdDone />
      },
      {
        title: 'Developer Assignment automation and code quality evaluation',
        render: <MdDone />
      },
      {
        title:
          'Expanding focused talent pool to ensure additional relevant developers in your pipeline via Support',
        render: <MdDone />
      },
      {
        title:
          'All feature early-access, including developer evaluation enhancements',
        render: <MdDone />
      },
      {
        title: 'Support level provided',
        render: 'Email'
      }
    ]
  }
}
