import React from 'react'
import {
  ABOUT_PAGE,
  ADMIN_ACTIVITY_PAGE,
  ADMIN_CONTACT_PAGE,
  ADMIN_PAGE,
  ADVANCED_SEARCH_USER_PAGE,
  ALL_JOBS_PAGE,
  CERTIFICATION_PAGE,
  COLLEGE_LEADERBOARD_PAGE,
  CONTACT_PAGE,
  HOME_PAGE,
  JOB_LISTING_PAGE,
  LEADERBOARD_PAGE,
  PROCESS_CANDIDATE_PAGE,
  PROJECTS_INSIGHT_PAGE,
  PROJECTS_PAGE,
  PROJECT_LISTING_PAGE,
  RECRUITER_DASHBOARD_PAGE,
  RECRUITER_PAGE,
  RESUME_INSIGHT_PAGE,
  RESUME_INTRODUCTION_PAGE,
  RESUME_JOB_PAGE,
  RESUME_SUMMARY_PAGE,
  SEARCH_USER_PAGE,
  SHORTLIST_STUDENT_PAGE,
  SUPER_ADMIN_PAGE
} from '../../../navigation/routes'

const AINavigation = {
  title: 'AI Tools',
  target: RESUME_INSIGHT_PAGE,
  loggedIn: false,
  child: [
    {
      title: (
        <div className="flex items-start gap-2">
          <span className="bg-yellow-400 text-xs mt-[2px] text-white rounded-sm px-[3px]">
            Free
          </span>{' '}
          Resume Summary Generator
        </div>
      ),
      target: RESUME_SUMMARY_PAGE,
      child: []
    },
    {
      title: (
        <div className="flex items-start gap-2">
          <span className="bg-yellow-400 text-xs mt-[2px] text-white rounded-sm px-[3px]">
            Free
          </span>{' '}
          Tell Me About Yourself Writer
        </div>
      ),
      target: RESUME_INTRODUCTION_PAGE,
      child: []
    },
    {
      title: (
        <div className="flex items-start gap-2">
          <span className="bg-yellow-400 text-xs mt-[2px] text-white rounded-sm px-[3px]">
            Free
          </span>{' '}
          Cover Letter Service
        </div>
      ),
      target: RESUME_JOB_PAGE,
      child: []
    }
  ]
}

export const recruiterNavData = (userType, userID) => {
  const basicRecruiterNavData = [
    {
      title: 'Dashboard',
      target: RECRUITER_DASHBOARD_PAGE,
      child: []
    },
    {
      title: 'Jobs & Projects',
      target: '#',
      child: [
        {
          title: 'Your Jobs',
          target: JOB_LISTING_PAGE,
          child: []
        },
        {
          title: 'Your Projects',
          target: PROJECT_LISTING_PAGE,
          child: []
        }
      ]
    },
    {
      title: 'Candidates',
      target: '#',
      child: [
        {
          title: 'Process Candidates',
          target: PROCESS_CANDIDATE_PAGE,
          child: []
        },
        {
          title: 'Shortlists',
          target: SHORTLIST_STUDENT_PAGE,
          child: []
        }
      ]
    },

    {
      title: 'Generate Profile',
      target: SEARCH_USER_PAGE,
      child: []
    },
    AINavigation,
    {
      title: 'Search Users',
      target: ADVANCED_SEARCH_USER_PAGE,
      child: []
    },
    {
      title: 'Team',
      target: `/create-user/${userID}`,
      child: []
    }
  ]

  switch (userType) {
    case 'ADMIN':
      return [
        ...basicRecruiterNavData,
        {
          title: 'Admin Portal',
          target: '#',
          child: [
            {
              title: 'All Jobs',
              target: ADMIN_PAGE,
              child: []
            },
            {
              title: 'Messages',
              target: ADMIN_CONTACT_PAGE,
              child: []
            }
          ]
        }
      ]
    case 'SUPER_ADMIN':
      return [
        ...basicRecruiterNavData,
        {
          title: 'Admin Portal',
          target: '#',
          child: [
            {
              title: 'All Jobs',
              target: ADMIN_PAGE,
              child: []
            },
            {
              title: 'All Users',
              target: SUPER_ADMIN_PAGE,
              child: []
            },
            {
              title: 'Messages',
              target: ADMIN_CONTACT_PAGE,
              child: []
            },
            {
              title: 'Recent Activity',
              target: ADMIN_ACTIVITY_PAGE,
              child: []
            }
          ]
        }
      ]
    default:
      return basicRecruiterNavData
  }
}
export const githubCandidateNavData = (username) => {
  return [
    AINavigation,
    {
      title: 'Dashboard',
      target: `/dashboard/${username}`,
      child: []
    },
    {
      title: 'Your Jobs',
      target: `/${username}/jobs`,
      child: []
    }
  ]
}

export const unauthNavData = [
  {
    title: 'Home',
    target: HOME_PAGE,
    loggedIn: false,
    child: []
  },
  {
    title: 'Hire Candidate',
    target: RECRUITER_PAGE,
    loggedIn: false,
    child: [
      // 	title: 'Open Jobs',
      // 	target: ALL_JOBS_PAGE,
      // 	child: [],
      // },
    ]
  },
  {
    title: 'Projects',
    target: PROJECTS_INSIGHT_PAGE,
    loggedIn: false,
    child: [{ title: 'Open Projects', target: PROJECTS_PAGE, child: [] }]
  },

  AINavigation,

  {
    title: 'Leaderboard',
    target: LEADERBOARD_PAGE,
    loggedIn: false,
    child: [
      {
        title: 'Colleges',
        target: COLLEGE_LEADERBOARD_PAGE,
        child: []
      }
    ]
  },
  {
    title: 'Certification',
    target: CERTIFICATION_PAGE,
    loggedIn: false,
    child: []
  },
  {
    title: 'About Us',
    target: ABOUT_PAGE,
    loggedIn: false,
    child: []
  }
]
